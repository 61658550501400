export default {
    // Global page headers: https://go.nuxtjs.dev/config-head
    head: {
        title: 'Bicycle Shipping Service - Bike Shipping Boxes - Best Way to Ship a Bike - Bikeflights.com',
        htmlAttrs: {
        lang: 'en'
        },
        meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' },
        { hid: 'description', name: 'description', content: 'Bikeflights.com is a bicycle shipping service. You get fast delivery, great prices and excellent service with every shipment per our Best Way Guarantee.' },
        { name: 'format-detection', content: 'telephone=no' }
        ],
        link: [{ rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }, {rel:'stylesheet', href:'https://fonts.cdnfonts.com/css/proxima-nova-2'}],
        script: [
          { src: '//www.googletagmanager.com/gtag/js?id=AW-1033208154', defer: true, body: true },
          { src: '/tracking/corewebvitals.js', defer: true, body: true },
          { src: '/tracking/googleAnalytics.js', defer: true, body: true },
          { src: '/tracking/multiSite.js', defer: true, body: true },
          { src: '/tracking/bing.js', defer: true, body: true },
          { src: '/tracking/affiliate.js', defer: true, body: true }
        ]
    },
    // Global CSS: https://go.nuxtjs.dev/config-css
    css: [
        '@/assets/css/main.css'
    ],
    // Plugins to run before rendering page: https://go.nuxtjs.dev/config-plugins
    // Auto import components: https://go.nuxtjs.dev/config-components
    components: true,
    // Modules for dev and build (recommended): https://go.nuxtjs.dev/config-modules
    buildModules: [
        // https://go.nuxtjs.dev/typescript
        '@nuxt/typescript-build',
        // https://go.nuxtjs.dev/stylelint
        '@nuxtjs/stylelint-module',
        // https://go.nuxtjs.dev/tailwindcss
        '@nuxtjs/tailwindcss',
        // https://google-fonts.nuxtjs.org/setup
        '@nuxtjs/google-fonts',
        '@nuxtjs/fontawesome',
        '@nuxt/image'
    ],
    fontawesome: {
        component: 'Fa',
        suffix: false,
        icons: {
        solid: [ 'faPlus', 'faMinus', 'faXmark', 'faCheck', 'faAngleUp', 'faAngleDown', 'faLock', 'faLocationDot', 'faAngleRight', 'faAngleLeft'],
          brands: false
        }
    },
    image: {
        cloudinary: {
            baseURL: process.env.CLOUDINARY_CDN
        },
        provider: 'gcloud',
        providers: {
            gcloud: {
                provider: require.resolve('./providers/gcloud'),
                options: {
                    baseURL: process.env.CDN
                }
            }
        },
        presets: {
            hero: {
              modifiers: {
                // format: 'webp', // Cuando Cloudinary transforma la imagen a WEBP pesa mas que la que le pasamos a menos que le coloque una alta compresion asi que no usare esto aun, solo lo estoy probando, si ves esto en live activo se me paso, comentalo :)
                width: 2000,
                height: 600,
                quality: 60,
                fit: "cover"
              }
            },
            webp_medium: {
              modifiers: {
                format: 'webp',
                quality: 60,
                fit: "cover"
              }
            }
        }
    },
    // Modules: https://go.nuxtjs.dev/config-modules
    modules: [
      ['@nuxtjs/axios', { credentials: true }],
      '@nuxtjs/auth-next',
      '@nuxtjs/sentry',
      '@nuxtjs/proxy'
    ],

    sentry: {
      dsn: process.env.SENTRY_DNS, // Enter your project's DSN here
      initialize: true,
      sourceMapStyle: 'source-map',
      publishRelease: {
        authToken: process.env.SENTRY_AUTH_TOKEN,
        org: process.env.SENTRY_ORG,
        project: process.env.SENTRY_PROJECT,
        release: {
          // Attach commits to the release (requires that the build triggered within a git repository).
          setCommits: {
            auto: true
          }
        }
      },
      attachCommits: true,
      repo: process.env.SENTRY_ORG +'/'+ process.env.SENTRY_PROJECT,
      authToken: process.env.SENTRY_AUTH_TOKEN,
      webpackConfig: {
        include: ['./'],
        ignore: ['node_modules']
      }
    },
    /* proxy: {
      '/Labels/user-label/*': {
        target: process.env.API_URL + '/api',
        changeOrigin: true,
        logLevel: 'debug',
        cookieDomainRewrite:{
          "*": process.env.API_URL
        },
        withCredentials: true,
        onProxyRes: (proxyRes, req, res) => {
          proxyRes.headers.Cookie = proxyRes.headers.Cookie && proxyRes.headers.Cookie.split(',');
        },
      }
    }, */

    auth: {
        // rewriteRedirects: true,
        watchLoggedIn: false, // add this line
        plugins: [ '~/plugins/auth.js' ],
        strategies: {
            local: false,

            cookie: {

              strategy: 'cookie',

              cookie: {
                  // (optional) If set, we check this cookie existence for loggedIn check
                  // name: '.AspNetCore.Identity.Application',
                  options: {

                    httpOnly: false ,
                    expires: 8,
                    secure: false
                  }

              },

              user: {
                property: 'value',
                autoFetch: true

              },

              endpoints: {
                  login: { url: '/api/Authentication/login', method: 'post' },
                  user: false,
                  logout: { url: '/api/Authentication/logout', method: 'post' }
              }
            }
        },
        redirect: {
            login: '/login',
            logout: '/login',
            callback: false,
            home: '/profile'
        }
    },
    serverMiddleware: [
      (req, res, next) => {
        if (req && req.url && req.url.endsWith && req.url.endsWith('.js')) {
          res.setHeader('Cache-Control', 'private, max-age=10800');
        }
        next();
      }
    ],
    middleware: ["auth"],
    // Axios module configuration: https://go.nuxtjs.dev/config-axios
    axios: {
        // Workaround to avoid enforcing hard-coded localhost:3000: https://github.com/nuxt-community/axios-module/issues/308
        baseURL: '/',
        headers: {
          common: {
            'Access-Control-Allow-Headers': 'cache-control',
            'cache-control': 'private, max-age=10800'
          }
        }
    },
    // Build Configuration: https://go.nuxtjs.dev/config-build
    build: {
        filenames: {
          chunk: ({ isDev, isModern }) => isDev ? `[name]${isModern ? '.modern' : ''}.js` : `[contenthash:7]${isModern ? '.modern' : ''}.js`
        },
        postcss: {
            order: ['tailwindcss/nesting', 'tailwindcss', 'autoprefixer'],
            postcssOptions: {
                plugins: {
                    tailwindcss: {},
                    autoprefixer: {},
                    'postcss-import': {},
                    'tailwindcss/nesting': {},
                    'postcss-nesting': {},
                    'postcss-flexbugs-fixes': {},
                    'postcss-preset-env': {
                        autoprefixer: {},
                        stage: 3,
                        features: {
                            'custom-properties': false,
                            'nesting-rules': true
                        }
                    }
                }
            }
        },
        features: {
          store: true,
          layouts: true,
          meta: true,
          middleware: true,
          transitions: false,
          deprecations: false,
          validate: false,
          asyncData: true,
          fetch: false,
          clientOnline: true,
          clientPrefetch: true,
          componentAliases: true,
          componentClientOnly: true
        },
        extend(config, { isDev, isClient }) {
          if (isDev) {
            config.devtool = isClient ? 'source-map' : 'inline-source-map';
          } else {
            // Disable source maps in production
            config.devtool = false;
          }
        }
    },
    googleFonts: {
        families: {
            Montserrat: [100, 200, 300, 400, 500, 600, 700, 800, 900]

        },
        display: 'swap'

    },
    optimization: {
      splitChunks: {
        layouts: true,
        pages: true,
        commons: true,
        cacheGroups: {
          // Create a separate chunk for vendor code
          vendors: {
            test: /[\\/]node_modules[\\/]/,
            name: 'vendors',
            chunks: 'all',
            priority: -10,
            enforce: true
          },
          // Create a separate chunk for shared modules
          common: {
            name: 'common',
            chunks: 'all',
            minChunks: 2,
            priority: -20,
            reuseExistingChunk: true
          }
        },
        // Split code based on async chunks
        chunks: 'async',
        // Enforce splitting
        enforce: true,
        // Control minimum and maximum chunk size
        minSize: 30000,
        maxSize: 50000
      }
    },
    router: {
      prefetchLinks: false,
      middleware: ['analytics','checkVersion']
    },
    plugins: [
        '~/plugins/ClickOutsideDirective.ts',
        '~/plugins/ImageFallbackDirective.ts',
        '~/plugins/Axios.ts',
        '~/plugins/setToken.ts',
      { src: '~/plugins/LoadInSyncAI.ts', mode: 'client' }
    ],
    publicRuntimeConfig: {
      mapsKey: process.env.MAPS_KEY,
      apiURL: process.env.API_URL,
      cdn: process.env.CDN,
      stripePK: process.env.STRIPE_PK_KEY,
      env: process.env.ENV,
      inSyncAi: {
        env: process.env.VUE_APP_INSYNC_ENV,
        idDev: process.env.VUE_APP_INSYNC_APP_ID_DEV,
        idStage: process.env.VUE_APP_INSYNC_APP_ID_STAGE,
        idProd: process.env.VUE_APP_INSYNC_APP_ID_PROD,
      }
    },
    modern: true,
    aggressiveCodeRemoval: true
}
